import React, { FC } from 'react'
import Image from 'next/image'
import { Box, Text, Stack, LinkOverlay } from '@chakra-ui/react'
import { CardLink } from 'src/uikit'

const Brand: FC<BrandGlobal.BrandItem> = ({ name, image, href, actionTitle }) => (
  <Box w="100%" h="124px">
    <CardLink href={href} title={actionTitle}>
      <Box h="5rem" w="3rem" margin="auto" pos="relative">
        <Image src={image} layout="fill" loading="lazy" />
      </Box>
      <Stack align="center">
        <LinkOverlay href={href}>
          <Text
            fontFamily="Montserrat-Medium"
            fontSize=".75rem"
            textAlign="center"
            color="baseNeutral.600"
            letterSpacing=".4px"
          >
            {name}
          </Text>
        </LinkOverlay>
      </Stack>
    </CardLink>
  </Box>
)

export default Brand
