import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Box, Stack, useBreakpointValue } from '@chakra-ui/react'
import { H3Title } from 'src/uikit'
import Carousel from '../common/Carousel'
import Brand from './Brand'

interface Props {
  title: string
  brands: BrandGlobal.BrandItem[]
}

const BrandsList: FC<Props> = ({ title, brands }) => {
  const { t } = useTranslation()
  const isMobile = useBreakpointValue({ base: true, md: false })

  return <Box pt="xxl6">
    <Container as={Stack} maxW="6xl" pb="xxl6">
      <H3Title>{title}</H3Title>
      <Box h="300px">
        <Carousel
          grid={{ rows: 2 }}
          slidesPerView={isMobile ? 2 : 7}
          spaceBetween={28}
          withScrollbar
          className="home-carousel"
        >
          {brands.map(brand => {
            return (
              <Carousel.Item key={brand.name}>
                <Brand
                  name={brand.name}
                  image={brand.image}
                  href={`/noleggio-a-lungo-termine/${brand.href}`}
                  actionTitle={t('indexPage:brands.actionTitle', { brand: brand.name })}
                />
              </Carousel.Item>
            )
          })}
        </Carousel>
      </Box>
    </Container>
  </Box>
}

export default BrandsList
